<template>
  <v-form style="min-height: 100px; position: relative;">
    <v-overlay :value="loading" absolute color="white">
      <v-progress-circular
        class="mt-2"
        size="25"
        width="2"
        indeterminate
        color="primary"
      />
    </v-overlay>
    <div v-for="(item, index) in states" :key="item.id">
      <form-subheader :title="`${item.defaultValue}`" class="pt-4" />
      <v-select
        v-model="localState[index].property"
        :items="properties"
        :loading="false"
        item-text="name"
        item-value="name"
        label="Property"
        @input="sendData"
      />
    </div>
  </v-form>
</template>

<script>
import { useLazyQuery, useResult } from '@vue/apollo-composable';

import schemaState from '@/modules/object-types/api/state-list.graphql';
import schemas from '@/modules/objects/api/object-from-devices.graphql';

import { onMounted, ref, watch } from '@vue/composition-api';

export default {
  name: 'StatesTab',
  props: {
    data: { type: Object, default: () => ({}) },
    tab: { type: Number, default: null }
  },
  setup(props, { emit }) {
    const localState = ref([]);
    const fetchState = useLazyQuery(
      schemaState.load,
      {
        parentSchemaId: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    const driverQuery = useLazyQuery(
      schemas.fetchDriver,
      {
        id: ''
      },
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
      }
    );

    fetchState.onResult(({ data }) => {
      if (localState.value.length === 0) {
        data.schemata.forEach(item => {
          localState.value.push({
            idOfState: item.id,
            property: ''
          });
        });
      }
    });

    const states = useResult(fetchState.result, [], data =>
      data.schemata.map(item => ({
        idOfState: item.id,
        ...item.schemaProperties.find(itemInner => itemInner.key === 'infoName')
      }))
    );

    const properties = useResult(driverQuery.result, [], data =>
      data.schemata[0].schemaProperties.map(item => ({
        id: item.id,
        name: `${item.groupName}/${item.property}`
      }))
    );

    watch(
      () => props.tab,
      () => {
        initData();
      }
    );

    const initData = () => {
      const parentSchemaId = props.data['general'].type;
      const schemaDriverId = props.data['general'].objectType;

      if (parentSchemaId) {
        fetchState.variables.value.parentSchemaId = parentSchemaId;
        fetchState.load();
      }

      if (schemaDriverId) {
        driverQuery.variables.value.id = schemaDriverId;
        driverQuery.load();
      }
    };

    const sendData = () => {
      emit('update-form', {
        key: 'states',
        data: localState.value.filter(item => item.property)
      });
    };

    onMounted(() => {
      initData();
    });

    return {
      sendData,
      states,
      properties,
      localState,
      loading: fetchState.loading || driverQuery.loading
    };
  }
};
</script>
